<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="设置"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="当前信用额度(元)">
          {{ currentCredit | formatFloat }}
        </a-form-item>

        <a-form-item label="新增信用额度(元)">
          <a-input-number
            :max="10000000"
            :step="0.01"
            :precision="2"
            style="width: 100%;"
            @blur="handleAddCreditChange"
            v-decorator="['add_credit', {
              rules: [
                { required: true, message: '请输入新增信用额度' }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="合计信用额度(元)">
          <a-input-number
            disabled
            :min="0"
            :max="10000000"
            :step="0.01"
            :precision="2"
            style="width: 100%;"
            v-decorator="['credit', {
              rules: [
                { required: true, message: '请输入新信用额度' }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="`备注`">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { max: 200, message: '最多200个字符' },
              ]
            }]"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createAgentCreditHistories, findAgentCreditHistoriesForm } from '@/api/agent_credit_history'

export default {
  name: 'NewAgentCreditHistory',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'agent_credit_history' }),
      currentCredit: 0,
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findAgentCreditHistoriesForm(this.agentId).then((res) => {
        this.currentCredit = res.data.current_credit
      })
    },

    handleAddCreditChange() {
      this.form.validateFields(['add_credit'], { force: true }, (err, values) => {
        if (!err) {
          var newCredit = values.add_credit + this.currentCredit

          this.form.setFieldsValue({
            credit: newCredit
          })
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = Object.assign({ agent_id: this.agentId }, values)
          createAgentCreditHistories(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
